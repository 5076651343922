<template>
  <div class="main">
    <div class="deal">
      <div className="policy ">
        <p className="text-center text-3xl font-bold pt-5 pb-5">云彩人资科研-隐私政策</p>
        <div className="bg-neutral-100 p-3 rounded-md">
          <div className="date-box">
            <p className="text-base font-semibold py-1">政策发布日期：2024年10月1日</p>
            <p className="text-base font-semibold py-1">政策生效日期：2024年10月1日</p>
          </div>
          <div className="leading-6 pt-8 text-sm">
            ㅤㅤ隐私政策尊重并保护所有使用隐私政策网络服务用户的个人隐私权。但隐私政策将以高度的勤勉、审慎义务对待这些信息。隐私政策会不时更新本隐私权政策。
            您在同意隐私政策网络服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于隐私政策网络服务使用协议不可分割的一部分。你不是一定要提供我们（深圳市云彩人资科研技术有限公司）要求的个人信息，但在许多情况下，如果你选择不提供，我们（深圳市云彩人资科研技术有限公司）将无法为你提供我们（深圳市云彩人资科研技术有限公司）的产品或服务，也无法回应你遇到的任何问题。
            <br />
            一、我们如何收集信息
            <br />
            ㅤㅤ我们（深圳市云彩人资科研技术有限公司）根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。
            <br />
            ㅤㅤ我们（深圳市云彩人资科研技术有限公司）公司收集个人信息的主要来源有：您提供的信息、在您使用我们（深圳市云彩人资科研技术有限公司）产品或服务过程中获取的信息、第三方分享的您的信息。
            <br />
            ㅤㅤ我们（深圳市云彩人资科研技术有限公司）会出于如下产品功能的目的，收集和使用您的个人信息，如您不提供相关信息或不同意我们（深圳市云彩人资科研技术有限公司）收集相关信息，您可能无法注册成为我们（深圳市云彩人资科研技术有限公司）的用户或无法享受我们（深圳市云彩人资科研技术有限公司）提供的某些服务，或无法达到好的服务效果。
            <br />
            1. 您提供账号注册、登录功能及服务，可能需要您提供如下账号注册信息：
            <br />
            ㅤ(1)当您注册账号时，会向我们（深圳市云彩人资科研技术有限公司）提供手机号码、登录密码。
            <br />
            ㅤ(2)当您选择使用第三方账号登录的，我们（深圳市云彩人资科研技术有限公司）会读取您在第三方账号注册时使用的头像、昵称信息。前述第三方账号包括QQ、微信、微博或其他第三方账号，以具体产品和服务实际接入的第三方账号为准。
            <br />
            ㅤ(3)当您选择使用小程序【签约工具包】时，我们（深圳市云彩人资科研技术有限公司）将会收集、使用和存储用户信息。
            <br />
            ㅤ(4)收集前述这些账号信息的目的在于：
            <br />
            ㅤㅤ1）通过账号管理更好地为您提供服务，通过登录账户，您可以同步您的相关信息。
            <br />
            ㅤㅤ2）如您提供真实有效的手机号码、QQ号、微博号、微信号进行绑定，当您遇到注册账号丢失、忘记密码问题时，可以便捷的找回账号和密码。
            <br />
            ㅤㅤ3）我们（深圳市云彩人资科研技术有限公司）将对所收集的相关信息去标识化后进行统计分析，以便更加准确了解用户的习惯，并在此基础上不断改善产品、提高服务质量及改善产品用户使用体验。
            <br />
            ㅤ(3)我们（深圳市云彩人资科研技术有限公司）收集的个人用户信息将用于为用户展示个人的薪资信息。
            <br />
            2、您通过我们（深圳市云彩人资科研技术有限公司）的客服或参加我们（深圳市云彩人资科研技术有限公司）举办的活动时所提交的信息。为及时与您取得联系，反馈客服问题或相关活动信息，您可能会向我们（深圳市云彩人资科研技术有限公司）提供您的联系方式，以便及时沟通。
            <br />
            3. 设备信息
            <br />
            ㅤㅤ设备信息，是指您使用我们（深圳市云彩人资科研技术有限公司）产品或服务时使用的设备的信息。
            <br />
            ㅤㅤ为了产品和服务与设备进行必要的适配及提供安全服务，您在使用产品或服务时，会收集您使用搜狗公司产品或服务的设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN）相关信息。收集这些信息是为了帮助我们（深圳市云彩人资科研技术有限公司）进行bug分析，保障您正常使用我们（深圳市云彩人资科研技术有限公司）产品和服务、改进和优化我们（深圳市云彩人资科研技术有限公司）的产品体验、保障您的账号安全。除本政策另有明确约定外，我们（深圳市云彩人资科研技术有限公司）不会将您的设备信息提供给任何第三方。但我们（深圳市云彩人资科研技术有限公司）无法保证其他第三方不会通过其他途径获知您设备信息、并将设备信息与其他信息相结合识别您个人身份的情况，由此造成您任何损失的，您应向第三方追偿。上述设备信息是为提供服务所收集的基础信息，如您不想设备信息被我们（深圳市云彩人资科研技术有限公司）收集，则可能导致我们（深圳市云彩人资科研技术有限公司）无法向您提供正常的服务。
            <br />
            4. 位置信息
            <br />
            ㅤㅤ位置信息，是指您开启设备定位功能并使用基于位置提供的相关服务时，收集的有关您位置的信息，主要包括：
            <br />
            ㅤㅤ(1)当您开启设备定位功能并使用相关产品或服务时，我们（深圳市云彩人资科研技术有限公司）可能会使用GPS、WiFi或其他技术方式收集和处理有关您实际所在位置的信息。行踪轨迹、精准定位信息为个人敏感信息，除具体产品或服务功能所需外，我们（深圳市云彩人资科研技术有限公司）的产品或服务不会收集该类个人敏感信息。
            <br />
            ㅤㅤ(2)您或其他用户提供的包含您所处地理位置的实时信息。包括您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息。
            <br />
            ㅤㅤ(3)如您不想被访问，可以选择关闭设备或产品/服务中的相关功能，但可能会因此影响我们（深圳市云彩人资科研技术有限公司）向您提供相关服务。
            <br />
            5、日志信息
            <br />
            ㅤㅤ出于服务安全的必要，您使用产品和服务时，系统可能通过cookies或其他方式自动收集某些信息并存储在服务器日志中。此类信息可能包括：
            <br />
            ㅤㅤ(1)对我们（深圳市云彩人资科研技术有限公司）的产品和服务的详细使用情况，此种信息可能包括您使用的网页搜索词语、访问的页面地址、以及您在使用服务时浏览或要求提供的其他信息和内容详情；设备或软件信息，除以上所述设备信息外，我们（深圳市云彩人资科研技术有限公司）可能收集您使用的软件的版本号、浏览器类型。
            <br />
            ㅤㅤ(2)为确保您操作环境的安全或提供服务所需，我们（深圳市云彩人资科研技术有限公司）会收集有关您使用的移动应用和其他软件的信息。
            <br />
            ㅤㅤ(3)设备事件信息，包括崩溃、系统活动信息及其他相关信息。
            <br />
            6. 经您同意后收集的其他信息
            <br />
            ㅤㅤ若您选择不提供该类信息，则可能无法正常使用对应功能，但不影响您使用服务中的其他功能。这些功能包括：
            <br />
            ㅤㅤ(1)基于摄像头（相机）的应用功能：您可在开启相机/摄像头权限后完成视频拍摄、拍照、扫码以及人脸识别的功能。未来我们（深圳市云彩人资科研技术有限公司）可能会将人脸识别技术应用于更多场景，但那时我们（深圳市云彩人资科研技术有限公司）会再次与您确认您是否愿意我们（深圳市云彩人资科研技术有限公司）使用您的面部信息来实现这些扩展功能。
            <br />
            ㅤㅤ(2)基于相册（图片库/视频库）的图片/视频访问及上传的应用功能：您可在开启相册权限后使用对应功能对你的照片/图片/视频进行编辑处理。
            <br />
            ㅤㅤ(3)基于麦克风语音技术的应用功能：您可在开启麦克风权限后直接使用麦克风来进行录音或是实现对应的功能。在这些功能中我们（深圳市云彩人资科研技术有限公司）会收集您的录音以识别您语音内容，实现对应的功能效果。
            <br />
            ㅤㅤ(4)基于通讯录信息的应用功能：我们（深圳市云彩人资科研技术有限公司）将在您开启通讯录权限后收集您的通讯录信息，使你在使用对应功能时可以更便利的取用您通讯录内的联系人信息，无需再手动输入。
            <br />
            ㅤㅤ(5)基于日历的应用功能：我们（深圳市云彩人资科研技术有限公司）将在您开启可读取/写入您日历的权限后，我们（深圳市云彩人资科研技术有限公司）将收集您的日历信息用于备忘录记录及提醒。
            <br />
            ㅤㅤ(6)基于手机文件读取的应用功能：您可在开启文件读取/上传的权限后使用对应功能对手机内的文件进行编辑处理。
            <br />
            ㅤㅤ您理解并同意，上述应用功能可能需要您在您的设备中向我们（深圳市云彩人资科研技术有限公司）开启您的相机（摄像头）、相册（图片库）、麦克风（语音）、通讯录及日历的访问权限，以实现这些功能所涉及的信息的收集和使用。请您注意，收集个人敏感信息前，我们（深圳市云彩人资科研技术有限公司）会以弹窗或类似显著方式获取您的同意。若您主动提供您的个人敏感信息，即表示您同意我们（深圳市云彩人资科研技术有限公司）按本政策所述目的和方式来处理您的个人敏感信息。
            <br />
            7. 其他第三方分享的您的信息
            <br />
            ㅤㅤ主要包括两种情况：
            <br />
            ㅤㅤ(1)其他用户发布的信息中可能包含您的信息。
            <br />
            ㅤㅤ(2)您在使用第三方合作伙伴服务时所产生或分享的信息，当您使用微博、QQ账号及其他第三方账号登录我们（深圳市云彩人资科研技术有限公司）的软件产品或服务时，我们（深圳市云彩人资科研技术有限公司）需要核实您的身份或绑定您的账户信息，经过您的授权将相关账号关联到我们（深圳市云彩人资科研技术有限公司）的产品或服务中。
            <br />
            二、我们如何使用收集的信息
            <br />
            ㅤㅤ我们（深圳市云彩人资科研技术有限公司）严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我们（深圳市云彩人资科研技术有限公司）超出以下用途使用您的信息，我们（深圳市云彩人资科研技术有限公司）将再次向您进行说明，并征得您的同意。
            <br />
            ㅤㅤ(1)满足您的个性化需求 ：例如，语言设定、位置设定、个性化的帮助服务。
            <br />
            ㅤㅤ(2)向您提供服务，产品开发和服务优化
            ：例如，当我们（深圳市云彩人资科研技术有限公司）的系统发生故障时，我们（深圳市云彩人资科研技术有限公司）会记录和分析系统故障时产生的信息，优化我们（深圳市云彩人资科研技术有限公司）的服务。
            <br />
            ㅤㅤ(3)安全保障
            ：例如，我们（深圳市云彩人资科研技术有限公司）会将您的信息用于身份验证、安全防范、反诈骗监测、存档备份、客户的安全服务等用途。例如，您下载或安装的安全软件会对恶意程序或病毒进行检测，或为您识别诈骗信息。
            <br />
            ㅤㅤ(4)评估、改善我们（深圳市云彩人资科研技术有限公司）的广告投放和其他促销及推广活动的效果。
            <br />
            ㅤㅤ(5)管理软件 ：例如，进行软件认证、软件升级等。
            <br />
            ㅤㅤ(6)邀请您参与有关我们（深圳市云彩人资科研技术有限公司）服务的调查。
            <br />
            ㅤㅤ(7)根据法律法规在如下情形收集使用您的相关信息，此种情况下无须获得您的授权：
            <br />
            ㅤㅤㅤ1）与国家安全、国防安全有关的；
            <br />
            ㅤㅤㅤ2）与公共安全、公共卫生、重大公共利益有关的；
            <br />
            ㅤㅤㅤ3）与犯罪侦查、起诉、审判和判决执行等有关的；
            <br />
            ㅤㅤㅤ4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            <br />
            ㅤㅤㅤ5）用于维护所提供的产品与/或服务的安全稳定运行所必需的，包括发现、处置产品与/或服务的故障等；
            <br />
            ㅤㅤㅤ6）为合法的新闻报道所必需的；
            <br />
            ㅤㅤㅤ7）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            <br />
            ㅤㅤㅤ8）法律法规规定的其他情形。
            <br />
            ㅤㅤ(8)经您同意的其他用途。
            <br />
            ㅤㅤ为了让您有更好的体验、改善我们（深圳市云彩人资科研技术有限公司）的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们（深圳市云彩人资科研技术有限公司）可能将通过某些服务所收集的信息用于我们（深圳市云彩人资科研技术有限公司）的其他服务。
            <br />
            ㅤㅤ为了确保服务的安全，帮助我们（深圳市云彩人资科研技术有限公司）更好地了解我们（深圳市云彩人资科研技术有限公司）应用程序的运行情况，我们（深圳市云彩人资科研技术有限公司）可能记录相关信息，例如，您使用应用程序的频率、故障信息、总体使用情况、性能数据以及应用程序的来源。我们（深圳市云彩人资科研技术有限公司）不会将我们（深圳市云彩人资科研技术有限公司）存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合。
            <br />
            三、我们如何共享、转让、公开披露你的个人信息
            <br />
            ㅤㅤ我们（深圳市云彩人资科研技术有限公司）充分知晓因违法共享、转让、公开披露个人信息对个人信息主体造成损害时所应承担的法律责任，对于你的个人信息的一切共享、转让、公开披露，我们（深圳市云彩人资科研技术有限公司）将严格按照以下条款进行：
            <br />
            ㅤㅤ(一) 共享
            <br />
            ㅤㅤ尊重用户个人隐私是我们（深圳市云彩人资科研技术有限公司）的一项基本原则。除以下情形外，我们（深圳市云彩人资科研技术有限公司）不会与任何公司、组织和个人分享你的个人信息：
            <br />
            ㅤㅤ(1)在获取明确同意的情况下共享：获得你的明确同意后，我们（深圳市云彩人资科研技术有限公司）会与其他方共享你的个人信息。
            <br />
            ㅤㅤ(2)我们（深圳市云彩人资科研技术有限公司）可能会按照司法机关或行政机关的要求，对外共享你的个人信息。
            <br />
            ㅤㅤ(3)与关联方共享：你的个人信息可能会与我们（深圳市云彩人资科研技术有限公司）的关联公司共享。我们（深圳市云彩人资科研技术有限公司）只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求你的授权同意。
            <br />
            ㅤㅤ(4)与授权合作伙伴共享：仅为实现本政策中声明的目的，我们（深圳市云彩人资科研技术有限公司）的某些服务将由授权合作伙伴提供。我们（深圳市云彩人资科研技术有限公司）可能会与合作伙伴共享你的某些个人信息，以提供更好的客户服务和用户体验。我们（深圳市云彩人资科研技术有限公司）仅会出于合法、正当、必要、特定、明确的目的共享你的个人信息，并且只会共享提供服务所必要的个人信息，例如在你上网购买我们（深圳市云彩人资科研技术有限公司）的产品时必须与物流公司共享你的信息。我们（深圳市云彩人资科研技术有限公司）的合作伙伴无权将共享的个人信息用于任何其他用途。
            <br />
            ㅤㅤ对我们（深圳市云彩人资科研技术有限公司）与之共享个人信息的公司、组织和个人，我们（深圳市云彩人资科研技术有限公司）会与其签署严格的保密协定，要求他们按照我们（深圳市云彩人资科研技术有限公司）的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
            <br />
            ㅤㅤ（二）转让
            <br />
            ㅤㅤ我们（深圳市云彩人资科研技术有限公司）不会将你的个人信息转让给任何公司、组织和个人，但以下情况除外：
            <br />
            ㅤㅤ(1)在获取明确同意的情况下转让：获得你的明确同意后，我们（深圳市云彩人资科研技术有限公司）会向其他方转让你的个人信息。
            <br />
            ㅤㅤ(2)在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们（深圳市云彩人资科研技术有限公司）会在要求新的持有你个人信息的公司、组织继续受此隐私政策的约束，否则我们（深圳市云彩人资科研技术有限公司）将要求该公司、组织重新向你征求授权同意。
            <br />
            ㅤㅤ（三）公开披露
            <br />
            ㅤㅤ我们仅会在以下情况下，公开披露你的个人信息：
            <br />
            ㅤㅤ(1)在您明确同意的披露方式下披露您所指定的个人信息。
            <br />
            ㅤㅤ(2)根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们（深圳市云彩人资科研技术有限公司）可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们（深圳市云彩人资科研技术有限公司）收到上述披露信息的请求时，我们（深圳市云彩人资科研技术有限公司）会要求必须出具与之相应的法律文件，如传票或调查函。
            <br />
            四、我们如何保护你的个人信息
            <br />
            ㅤㅤ(1)我们（深圳市云彩人资科研技术有限公司）已使用符合业界标准的安全防护措施保护你提供的个人信息，
            防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们（深圳市云彩人资科研技术有限公司）会采取一切合理可行的措施，保护你的个人信息。例如，在你的浏览器与“服务”之间交换数据(如信用卡信息)时受SSL加密保护；我们（深圳市云彩人资科研技术有限公司）同时对网站提供https安全浏览方式；我们（深圳市云彩人资科研技术有限公司）会使用加密技术确保数据的保密性；我们（深圳市云彩人资科研技术有限公司）会使用受信赖的保护机制防止数据遭到恶意攻击；我们（深圳市云彩人资科研技术有限公司）会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们（深圳市云彩人资科研技术有限公司）会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
            <br />
            ㅤㅤ(2)我们（深圳市云彩人资科研技术有限公司）会采取一切合理可行的措施，确保未收集无关的个人信息。除非取得你的书面许可，我们（深圳市云彩人资科研技术有限公司）将在且仅在你删除或注销账户后36个月保留你的个人信息，
            超出此期限后，我们（深圳市云彩人资科研技术有限公司）将会对你的个人信息进行匿名化处理或销毁。
            <br />
            ㅤㅤ(3)互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们（深圳市云彩人资科研技术有限公司）强烈建议你不要通过此类方式发送个人信息。请使用复杂密码，协助我们（深圳市云彩人资科研技术有限公司）保证你的账号安全。
            <br />
            ㅤㅤ(4)互联网环境并非百分之百安全，我们（深圳市云彩人资科研技术有限公司）将尽力确保或担保你发送给我们（深圳市云彩人资科研技术有限公司）的任何信息的安全性。如果我们（深圳市云彩人资科研技术有限公司）的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致你的合法权益受损，我们（深圳市云彩人资科研技术有限公司）将承担相应的法律责任。
            <br />
            ㅤㅤ(5)在不幸发生个人信息安全事件后，我们（深圳市云彩人资科研技术有限公司）将按照《网络安全法》第42条第二款之规定及时向你告知：安全事件的基本情况和可能的影响、我们（深圳市云彩人资科研技术有限公司）已采取或将要采取的处置措施、你可自主防范和降低风险的建议、对你的补救措施等。我们（深圳市云彩人资科研技术有限公司）将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知你，难以逐一告知个人信息主体时，我们（深圳市云彩人资科研技术有限公司）会采取合理、有效的方式发布公告。同时，我们（深圳市云彩人资科研技术有限公司）还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
            <br />
            <!-- ㅤㅤ(6)您随时可注销此前注册的账户，在注销账户之后，我们将停止为您提供产品或服务并依据您的要求，删除或匿名化您的信息，法律法规另有规定的除外。这也将可能导致您失去对您账户中数据的访问权，请您谨慎操作。如果您需要注销您在官方渠道上注册的账号，您可以采取以下途径：通过【登入app】-【点击我的】-【注销账号】-【确定注销】；
            注销账号意味着我们将终止向您通过该渠道方账号提供服务，并且您登录我方服务所使用的渠道方账号一旦被注销将不可恢复，请您在操作之前自行备份相关所有信息和数据。 -->
            <br />
            五、您如何管理您的个人信息
            <br />
            ㅤㅤ(1)您可以在使用我们（深圳市云彩人资科研技术有限公司）服务的过程中，访问、修改和删除您提供的注册信息和其他个人信息，也可按照通知指引与我们（深圳市云彩人资科研技术有限公司）联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。
            <br />
            ㅤㅤ例如，若您在使用地理位置相关服务时，希望停止分享您的地理位置信息，您可通过手机定位关闭功能、软硬件服务商及通讯服务提供商的关闭方式停止分享，建议您仔细阅读相关指引。
            <br />
            ㅤㅤ(2)我们（深圳市云彩人资科研技术有限公司）将按照本政策所述，仅为实现我们（深圳市云彩人资科研技术有限公司）产品或服务的功能，收集、使用您的信息。如您发现我们（深圳市云彩人资科研技术有限公司）违反法律、行政法规的规定或者双方的约定收集、使用您的个人信息，您可以要求我们（深圳市云彩人资科研技术有限公司）删除。如您发现我们（深圳市云彩人资科研技术有限公司）收集、存储的您的个人信息有错误的，您也可以要求我们（深圳市云彩人资科研技术有限公司）更正。在您访问、修改和删除相关信息时，我们（深圳市云彩人资科研技术有限公司）可能会要求您进行身份验证，以保障帐号的安全。
            <br />
            ㅤㅤ(3)在以下情形中，按照法律法规要求，我们（深圳市云彩人资科研技术有限公司）将无法响应你的请求：
            <br />
            ㅤㅤㅤ1）与国家安全、国防安全直接相关的；
            <br />
            ㅤㅤㅤ2）与公共安全、公共卫生、重大公共利益直接相关的；
            <br />
            ㅤㅤㅤ3）与犯罪侦查、起诉、审判和判决执行等直接相关的；
            <br />
            ㅤㅤㅤ4）有充分证据表明你存在主观恶意或滥用权利的；
            <br />
            ㅤㅤㅤ5）响应你的请求将导致你或其他个人、组织的合法权益受到严重损害的；
            <br />
            ㅤㅤㅤ6）涉及商业秘密的。
            <br />
            六、关于儿童的个人信息
            <br />
            ㅤㅤ我们（深圳市云彩人资科研技术有限公司）的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。
            <br />
            ㅤㅤ对于经父母同意而收集未成年个人信息的情况，我们（深圳市云彩人资科研技术有限公司）只会在司法机关或行政机关要求、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
            <br />
            ㅤㅤ尽管当地法律和习俗对未成年人的定义不同，但我们（深圳市云彩人资科研技术有限公司）将不满
            16
            周岁的任何人均视为未成年人。如果我们（深圳市云彩人资科研技术有限公司）发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
            <br />
            ㅤㅤ如果我们（深圳市云彩人资科研技术有限公司）发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
            <br />
            七、本政策如何更新
            <br />
            ㅤㅤ我们（深圳市云彩人资科研技术有限公司）的隐私政策将不定时更新。通常情况下，我们（深圳市云彩人资科研技术有限公司）不会削减你按照本隐私政策所应享有的权利。
            <br />
            ㅤㅤ我们（深圳市云彩人资科研技术有限公司）会在本页面上发布更新后的版本并且以弹框或与弹框同等显著的方式通知你本政策所做的任何变更，你同意后，即表示同意受经修订的本政策的约束。
            <br />
            八、联系我们
            <br />
            ㅤㅤ如果您对此政策或个人信息保护问题有任何提问请发送邮件至（yuncai@ycrzky.com），我们将尽快与您联系。
          </div>
        </div>
        <div className="h-5"></div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 10px solid #fff;
  }
  .deal {
    margin: 10px;
    border: 2px solid #efefef;
    border-radius: 20px;
  }
  .policy {
    padding: 20px;
  }
  .date-box {
    margin-bottom: 20px;
  }
  .font-semibold {
    text-align: right;
  }
  .text-center {
    text-align: center;
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 20px;
  }
</style>
